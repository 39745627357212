<script setup lang="ts">
// a pleasant circle element, waiting for contents. expects 1-2 chars/nums
// or an SVG via its slot. size SVGs via classes on the SVG's component.
// ex: <VPoint><FilterIcon class="h-5 w-5" /></VPoint>

interface Props {
  // tailwind sizes, each unit is 4px / 0.25rem
  color?: "indigo" | "emerald"
  size?: 8 | 6
  light?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  color: "indigo",
  size: 8,
  light: false,
})

const colorClasses: { [K in NonNullable<Props["color"]>]: string } = {
  emerald: "text-white bg-emerald-500",
  indigo: "text-white bg-indigo-500",
}

const colorClassesLight: { [K in NonNullable<Props["color"]>]: string } = {
  emerald: "text-emerald-500 bg-emerald-100",
  indigo: "text-indigo-500 bg-indigo-100",
}

const sizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  8: "w-8 h-8 leading-8",
  6: "w-6 h-6 leading-6 text-xs",
}

const classes = computed(() => {
  const classes = [sizeClasses[props.size]]
  const colors = props.light
    ? colorClassesLight[props.color]
    : colorClasses[props.color]
  classes.push(colors)
  return classes.join(" ")
})
</script>

<template>
  <div
    :class="classes"
    class="flex justify-center items-center rounded-full text-center font-extrabold"
  >
    <span :class="{ 'opacity-30': props.light }">
      <slot></slot>
    </span>
  </div>
</template>
